import React, { useEffect, useState } from 'react'
import { Grid, Popup, Radio } from "semantic-ui-react";
import TableWithBackground from "../../../framework/widgets/TableWithBackgrounds";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import AddNewAudit from "./AddNew Audit";
import ControllButton from "../../../framework/widgets/ActionButtons/ControllButton";
import TableHeader from "../../../framework/types/TableHeader";
import TableRow from "../../../framework/types/TableRow";
import tags from "../../../models/common/tag";
import { RoadSafetyAuditProps, RoadSafetyCoursesProps } from "./DataModal";
import { fetchAuditor, RemoveMefromAudit, UpdateMsgFromAdmin } from "./ProfileController";
import { getSessionCookie } from "../../../framework/common/SessionHandler";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import { PaginationProps } from "antd";

interface Props {
    states: any;
    auditStages: any;
    auditor: any;
    setAuditor: any;
    focusMode: any;
    setFocusMode: any;
    setStatChange: any;
    setRSA: any,
    rsa: any
}

const RoadSafetyAudits = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [auditId, setAuditId] = useState(0)
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const [isAddnew, setIsAddnew] = React.useState<boolean>(true);
    const [auditorId, setAuditorId] = useState<any>();
    const [trigger, setTrigger] = useState(false);
    const [rsaCourseDet, setRSACourseDet] = useState<any>([])
    const session = getSessionCookie();
    //const [rsa, setRSA] = useState<MsgFromAdmin>({} as MsgFromAdmin)
    const [isConfirmRSA, setIsConfirmRSA] = useState(false)
    const [isConfirmRSA2, setIsConfirmRSA2] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pages, setPages] = useState(1)
    const encid = new URLSearchParams(location.search).get('get')
    const Session = getSessionCookie();
    const [inforToggleRSA, setInforToggleRSA] = useState(false);
    const [auditorStatus, SetAuditorStatus] = useState(0)

    const toggleModel = (e: any) => {
        //alert(open)
        //eslint-disable-next-line
        //debugger;
        console.log(e)
        if (open && e) {
            callApi(true, true);
        } else {
            props.setFocusMode("audit")
            setOpen(true)
            props.setStatChange(true)
        }
    }
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Audit ID"
        },
        {
            text: "Sign-Off"
        },
        {
            text: "Audit"
        },
        {
            text: "Stage"
        },
        {
            text: "Lead Auditor"
        },
        {
            text: "Elapses"
        },
        {
            text: "Audit Status"
        },
        {
            text: "icon"
        },
        {
            text: "TfNSW"
        },
        {
            text: " "
        },
        {
            text: " "
        }
    ];

    useEffect(() => {
        SetAuditorStatus(props.auditor.personalDetails?.AuditorsStateRes?.auditorStatusId?.toString())
        callApi(false, true);
        if (props.auditor.RSA) {
            setInforToggleRSA(props.auditor.RSA.isActivated)
            setIsConfirmRSA(props.auditor.RSA.isConfirmedByAuditor ? props.auditor.RSA.isConfirmedByAuditor : false)
            setIsConfirmRSA2(props.auditor.RSA.isConfirmedByAuditor2 ? props.auditor.RSA.isConfirmedByAuditor2 : false)
        }

    }, [props.auditor])

    const onChange: PaginationProps['onChange'] = (page) => {
        console.log('ccccccccccccccccccccccc', props.auditor)
        const reload = fetchAuditor(
            (props.auditor.personalDetails.txtAuditorId != null && (Session.userRoleId == 1 || Session.userRoleId == 4))
                ? parseInt(props.auditor.personalDetails.txtAuditorId)
                : session.auditorId,
            page - 1
        );
        Promise.resolve(reload).then((value) => {
            //eslint-disable-next-line
            //debugger;
            SetValues(value);
        });
        setCurrent(page);
    };

    const callApi = (refresh: boolean, show: boolean) => {
        if (refresh) {
            setIsAddnew(true)
            const reload = fetchAuditor(
                (props.auditor.personalDetails.txtAuditorId != null && (Session.userRoleId == 1 || Session.userRoleId == 4))
                    ? parseInt(props.auditor.personalDetails.txtAuditorId)
                    : session.auditorId,
                0
            );
            Promise.resolve(reload).then((value) => {
                //eslint-disable-next-line
                //debugger;
                SetValues(value);
                if (show) {
                    setOpen(!open);
                    props.setStatChange(!open)
                }

            });
        } else {
            setPages(props.auditor.AtotalPages)
            Promise.resolve(props.auditor).then((value) => {
                //console.log(value)
                SetValues(value);
            })
        }

    }

    const onRSAChange = async (id: number, e: any, data: any, sectionName: string) => {
        console.log(isConfirmRSA)
        setIsConfirmRSA(!isConfirmRSA);
        await UpdateMsgFromAdmin(id, !isConfirmRSA, sectionName)
    }

    const onRSAChange2 = async (id: number, e: any, data: any, sectionName: string) => {
        setIsConfirmRSA2(!isConfirmRSA2);
        await UpdateMsgFromAdmin(id, !isConfirmRSA2, sectionName)
    }

    const AuditStageText = (stageId: number) => {
        switch (stageId) {
            case 1:
                return "Strategic design";
                break;
            case 2:
                return "Concept design";
                break;
            case 3:
                return "Detailed design";
                break;
            case 4:
                return "Roadworks";
                break;
            case 5:
                return "Pre-opening";
                break;
            case 6:
                return "Finalisation";
                break;
            case 7:
                return "";
                break;
            case 8:
                return "Existing Road";
                break;
            case 9:
                return "Thematic";
                break;
        }
    }

    const AuditStatusText = (stateId: number) => {
        //eslint-disable-next-line
        //debugger
        switch (stateId) {
            case 1:
                return "Pending to be verified";
                break;
            case 2:
                return "Pending in review process";
                break;
            case 3:
                return "Verified";
                break;
            case 5:
                return "Not recognised";
                break;
        }
    }

    const SetValues = (value: any) => {
        const audits = value.roadSafetyAudit;
        //const auditorDet =
        console.log('audit', audits)
        setAuditorId(value.personalDetails.txtAuditorId);
        const TableRows: object[] = [];
        audits.map((audit: RoadSafetyAuditProps) => {
            TableRows.push([
                (encid ? {
                    tag: tags.link,
                    text: audit.auditId,
                    icon: "",
                    href: `/audits?id=${audit.auditId}`,
                    onclick: null
                } : {
                    tag: tags.text,
                    text: audit.auditId,
                    icon: "",
                    onclick: null
                }),
                {
                    tag: tags.text,
                    text: audit.finalSignoffDate,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: audit.titleOfAudit,
                    icon: "",
                    onclick: null,
                    width: '200px'
                },
                {
                    tag: tags.text,
                    text: AuditStageText(parseInt(audit.stageOfAudit ? audit.stageOfAudit : "7")),
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: audit.teamLeaderName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: audit.elapseDate,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: AuditStatusText(parseInt(audit.auditStatusId ? audit.auditStatusId.toString() : "7")),
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "Auditor involvement",
                    icon: audit.isVerify == true ? "check" : "minus",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "tfnsw",
                    icon: audit.isTfNSW == true ? "check" : "minus",
                    onclick: null,

                },
                {
                    tag: "icon-fa",
                    text: "View Audit",
                    icon: "view",
                    onclick: (e: any) => {
                        setAuditId(audit.auditId ? audit.auditId : 0);
                        setIsAddnew(false)
                        setTimeout((e: any) => {
                            toggleModel(e)
                        }, 10);

                    }
                }, (encid ? {
                    tag: "icon-fa",
                    text: "Edit Audit",
                    icon: "edit",
                    onclick: (e: any) => {
                        window.open(`/audits?id=${audit.auditId}`, '_blank')
                    }
                } : null)
                /*{
                    tag:tags.button,
                    text:"Delete",
                    icon:"",
                    onclick:()=>{
                        const result = RemoveMefromAudit(value.personalDetails.txtAuditorId,audit.auditId);
                        Promise.resolve(result).then((value) => {
                            //props.setAuditor(fetchAuditor(session.auditorId))
                            callApi(true,false);
                        });
                        //
                    }
                }*/])
        })
        setIsEdit(encid != null && (Session.userRoleId != 1 && Session.userRoleId != 4) ? true : value.RSA.isActivated ? value.RSA.isActivated : value.Editable)
        props.setRSA(value.RSA);
        setRSACourseDet(TableRows);
    }

    const onRSAAdminBoxChange = (e: any) => {
        props.setRSA({
            ...props.rsa,
            isActivated: inforToggleRSA,
            Type: props.rsa.Type ? props.rsa.Type : 'AdminInfoToggle',
            msgFromAdmin: e != '' ? e.target.value : '',
            auditorId: parseInt(atob(encid ? encid : '')),
        })
    }

    return (
        <React.Fragment>
            <div className="ui center aligned icon header red-title" id={'audits'}>
                {encid != null && (session.userRoleId == 1 || session.userRoleId == 4) && (auditorStatus == 1 || auditorStatus == 2) ? (
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={14} mobile={12} tablet={13}>
                                <h1 className="ui center aligned icon header red-title">Road Safety Audits</h1>
                            </Grid.Column>
                            <Grid.Column width={2} style={{ paddingLeft: '4rem' }}>
                                <Popup
                                    flowing
                                    hoverable
                                    trigger={
                                        <Radio toggle checked={inforToggleRSA} onChange={() => {
                                            setInforToggleRSA(!inforToggleRSA)
                                            props.setRSA({
                                                ...props.rsa,
                                                isActivated: !inforToggleRSA,
                                                Type: props.rsa.Type ? props.rsa.Type : 'AdminInfoToggle',
                                                msgFromAdmin: props.rsa.msgFromAdmin ? props.rsa.msgFromAdmin : '',
                                                auditorId: parseInt(atob(encid)),
                                                // id: 0,
                                                // isConfirmedByAuditor: false,
                                                // sectionName: "RSC",
                                            })
                                        }} />
                                    }
                                >
                                    <Popup.Header>{inforToggleRSA ? 'Admin infotoggle is ON' : 'Admin infotoggle is OFF'}</Popup.Header>
                                    <Popup.Content>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <p>{inforToggleRSA ? `slide to save and close` : `slide to send message to auditor`}</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Popup.Content>
                                </Popup>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>) : (
                    <h1 className="ui center aligned icon header red-title">Road Safety Audits</h1>)}
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {props.rsa.isActivated ? (<InfoToggle
                            type={props.rsa.Type}
                            msg={props.rsa.msgFromAdmin}
                            update={props.rsa.update}
                            msg2={props.rsa.msgFromAdmin2 ? props.rsa.msgFromAdmin2 : ""}
                            update2={props.rsa.update2}
                            id={'chkAdminConformRSA'}
                            id2={'chkAdminConformRSA2'}
                            value={isConfirmRSA}
                            value2={isConfirmRSA2}
                            onChange={(e: any, data: any) => {
                                onRSAChange(props.rsa.id, e, data, props.rsa.sectionName)
                            }}
                            onChange2={(e: any, data: any) => {
                                onRSAChange2(props.rsa.id2 ? props.rsa.id2 : 0, e, data, props.rsa.sectionName)
                            }}
                            createDate={props.rsa.createDate}
                            createDate2={props.rsa.createDate2}
                            responceDate={props.rsa.ResponceDate}
                            responceDate2={props.rsa.ResponceDate2}
                            onMsgBoxChange={onRSAAdminBoxChange}
                            isConfirmedByAdminOnDate={props.rsa.isConfirmedByAdminOnDate}
                            createdBy={props.rsa.assessorName}
                            createdBy2={props.rsa.assessorName2}
                        />) : null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <TableWithBackground
                            title={"Audits Summary"}
                            subTitle={"Remember to attach your certificate for each of these road safety audit you have added"}
                            Component={() => (
                                <div style={{ minWidth: '10px', overflow: 'auto' }}>
                                    <RSATable
                                        head={rsaCourseHead}
                                        body={rsaCourseDet}
                                        pageCount={pages}
                                        onChange={onChange}
                                        current={current}
                                    />
                                </div>
                            )
                            }
                            modalTitle={isAddnew ? 'Add Audit Details' : 'View Audit Details'}
                            modalBody={() => (<AddNewAudit
                                states={props.states}
                                auditStages={props.auditStages}
                                auditId={auditId}
                                toggleModel={toggleModel}
                                isEdit={isAddnew ? isEdit : !isEdit}
                                auditorId={auditorId}
                                setAuditor={props.setAuditor}
                            />)}
                            buttonText={"Add Audit"}
                            modalAction={false}
                            open={open}
                            disabled={!isEdit}
                            setOpen={setOpen}
                            toggleModel={(e: any) => {
                                setIsAddnew(true)
                                toggleModel(e);
                                setAuditId(0)
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br />
            <hr />
        </React.Fragment>
    )
}

export default RoadSafetyAudits
