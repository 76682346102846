
/**
 * This function is used to validate the email address
 */
const emailValidator = (value: string) => {
    const mailformat = /^\w+([.'?-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/; //eslint-disable-line
    if (!value) {
        return false;
    }
    if (!value.match(mailformat)) {
        return false;
    }
    return true;
}


/**
 * This function is used to validate the telephone number
 */
const telephoneValidator = (value: string) => {

    const mailformat = /^\({0,1}((0|1|\+61|61)(\ |-){0,1}(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{2,3}$/im; //eslint-disable-line
    if (!value) {
        return false;
    }
    if (!value.match(mailformat)) {
        return false;
    }
    return true;
}

/**
 * This function is used to validate the number
 */
const numberValidator = (value: string) => {
    //debugger//eslint-disable-line
    const mailformat = /^[0-9]+$/; //eslint-disable-line
    if (!value) {
        return false;
    }
    if (!value.match(mailformat)) {
        return false;
    }
    return true;
}

/**
 * This function is used to validate the decimal number
 */

const decimalValidator = (value: string) => {

    const mailformat = /^[-+]?[0-9]+\.?[0-9]+$/; //eslint-disable-line
    if (!value) {
        return false;
    }
    if (!value.match(mailformat)) {
        return false;
    }
    return true;
}

export {
    emailValidator,
    telephoneValidator,
    numberValidator,
    decimalValidator
};